/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { authApi } from '../api/api.ts';
import { LoadingFullScreen } from '../components/LoadingFullScreen/LoadingFullScreen.tsx';

export default function LoginPage() {
  useEffect(() => {
    (async () => {
      const redirectUrl = authApi.getLoginRedirectUrl();
      window.location.assign(redirectUrl);
    })();
  }, []);

  return <LoadingFullScreen />;
}
